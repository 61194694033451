import { Link } from 'react-router-dom';
import profileImage from '../images/homepage/desktop/image-homepage-profile.jpg';
import profileImage2 from '../images/homepage/desktop/profile-erdem-2.jpg';

const About = ({sectionRef}) => {

  return (
    <section className="about-section" ref={sectionRef}>
      <div className="profile-image-wrapper">
        <img className='profile-image' src={profileImage2} alt="profile" />
        <div className="profile-image-overlay"></div>
      </div>
      <div className="about-text-wrapper">
        <h2>About Me</h2>
        <p className='about-me'>
        I'm a React Developer based in Toronto, Canada, with a passion for crafting engaging user experiences. While my primary focus is on writing accessible HTML, leveraging modern CSS practices, and mastering Javascript and React, I also have a growing appreciation for backend development. My goal is to continue expanding my experience and proficiency in Front End Development while working towards becoming a full-stack developer, where my expanding skill set in technologies like Express, MongoDB, Mongoose, and GraphQL can complement my expertise. When I'm not coding, you'll find me outdoors. I love being out in nature whether that's going for a run, playing tennis or cycling. I'd love you to check out my work.</p>
          <Link to='/portfolioindex'><button className="btn btn-secondary">Go to Portfolio</button></Link> 
      </div>
     
    </section>
  )
};

export default About